if (
  null === navigator.userAgent.toLowerCase().match(/(iphone|ipod|ipad|android)/)
) {
  $('.scroll-animate').each(function() {
    var block = $(this);
    $(window).scroll(function() {
      var top = block.offset().top;
      var bottom = block.height() + top;
      top = top - $(window).height() + 200;
      var scrollTop = $(this).scrollTop();
      if (scrollTop > top && scrollTop < bottom) {
        if (!block.hasClass('animate')) {
          block.addClass('animate');
          block.trigger('animateIn');
        }
      } else {
        block.removeClass('animate');
        block.trigger('animateOut');
      }
    });
  });
} else {
  $('.scroll-animate').css('opacity', 1);
}
