$(document).on("change", '#photoform input[name="filename[]"]', function(){
            
    var ext = ['jpg', 'gif', 'png', 'jpeg', 'bmp'];
    var error = false;

    var parts = $(this).val().split('.');
    if(ext.join().search(parts[parts.length - 1]) == -1){            
        var error = "Только изображения!";
        
    }
    var file_size = this.files[0].size;
    if(file_size > 5242880){
        var error = "Размер файла не более 5Mb!";                          
    }
    if(error) {
        $(this).closest('.up_wrapp').find('.up_remove').trigger("click");
        $(this).val('');
        $(this).closest('form').find('.up').removeClass("red");
        $(this).closest('label').addClass("red");
        $(this).closest('label').attr("data", error);
        return false;
    }
    $(this).closest('label').removeClass("red");            
});
$('#photoform')[0].reset();
$('#photoform').submit(function(){            
    if(checkForm($(this).get(0)) == false) return false;            
    var count = 0;           
    $('#photoform input[type=file]').each(function(){
        if($.trim($(this).val()) !== ''){
            count++;
        }            
    });            
    if(count == 0){               
        $(this).find('.up').first().addClass("red");
        $(this).find('.up').first().attr("data", "Прикрепите хотя бы один файл");
        return false;
    };           
});
$('.up').change(function() {
    $(this).closest('form').find('.up').first().removeClass("red");
$(this).addClass('uploaded');
$(this).siblings('.up_remove').addClass('active');
$(this).siblings('.up_remove').css('display', 'block');
});
$('.up_remove').click(function() {
    if($(this).hasClass('active')) {
        $(this).siblings('.uploaded').children('input').val('');
        $(this).removeClass('active');
        $(this).css('display', 'none');
        $(this).siblings('.up').removeClass('uploaded');
        $(this).siblings('.uploaded').children('.img_upload').css('opacity', 1);
    }
    // alert($(this).siblings('.uploaded').children('input').val());
    // $(this).removeClass('active');
});