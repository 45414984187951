const { checkForm } = require('../checkForm/checkForm.js');

$('.button').click(function() {
  $('body')
    .find('form:not(this)')
    .children('label')
    .removeClass('red');
   const url = `php/send.php`;


  const form = $(this).parent();
    
  const answer = checkForm(
    $(this)
      .parent()
      .get(0)
  );
  const recieved = $('#recieved');
  if (answer != false) {
    $('#recieved div').find( '.request_suc, .request_err').hide();
    $.ajax({
      type: 'POST',
      dataType: 'json',
      url: url,
      data: form.serialize(),
      success: () => {
        form
          .closest('form')
          .get(0)
          .reset();
        recieved.css('display', 'flex');
        $('#recieved div').find( '.request_suc' ).show();

      },
      error: (jqXHR, testStatus, errorThrown) => {
        recieved.css('display', 'flex');
        $('#recieved div').find( '.request_err' ).show();
      },
    });
  }
});
