require('maphilight');

$('.map').maphilight();
first();

$('#hood, .hood_btn, .hood_desc, #lights, .lights_btn, .lights_desc, #bumper1, .bumper1_desc, #wing1, .wing1_btn, .wing1_desc, #door, .door_btn, .door_desc, #roof, .roof_btn, .roof_desc, #bumper2, .bumper2_btn, .bumper2_desc, #wing2, .wing2_btn, .wing2_desc').hover(function () {
  var first1 = $('.first_thumb').val();
  if (first1 == 'true') {
    first();
    $('.first_thumb').val('false');
    $('.hood_desc').hide();
  }
});

$('#hood, .hood_btn, .hood_desc').hover(function (e) {
  e.preventDefault();
  var data = $('#hood').data('maphilight') || {};
  data.alwaysOn = !data.alwaysOn;
  data.fillColor = 'ffca00';
  data.stroke = true;
  data.strokeColor = 'ffca00';
  data.strokeWidth = 2;
  $('#hood').data('maphilight', data).trigger('alwaysOn.maphilight');
});
$('#hood, .hood_btn, .hood_desc').mouseenter(function () { $('.hood_desc').show(); });
$('#hood, .hood_btn, .hood_desc').mouseleave(function () { $('.hood_desc').hide(); });
/////////////////////////////////////////////////////////////////////////////////
$('#lights, .lights_btn, .lights_desc').hover(function (e) {
  e.preventDefault();
  var data = $('#lights').data('maphilight') || {};
  data.alwaysOn = !data.alwaysOn;
  data.fillColor = 'ffca00';
  data.stroke = true;
  data.strokeColor = 'ffca00';
  data.strokeWidth = 2;
  $('#lights').data('maphilight', data).trigger('alwaysOn.maphilight');
});
$('#lights, .lights_btn, .lights_desc').mouseenter(function () { $('.lights_desc').show(); });
$('#lights, .lights_btn, .lights_desc').mouseleave(function () { $('.lights_desc').hide(); });
/////////////////////////////////////////////////////////////////////////////////
$('#bumper1, .bumper1_desc').hover(function (e) {
  e.preventDefault();
  var data = $('#bumper1').data('maphilight') || {};
  data.alwaysOn = !data.alwaysOn;
  data.fillColor = 'ffca00';
  data.stroke = true;
  data.strokeColor = 'ffca00';
  data.strokeWidth = 2;
  $('#bumper1').data('maphilight', data).trigger('alwaysOn.maphilight');
});
$('#bumper1, .bumper1_desc').mouseenter(function () { $('.bumper1_desc').show(); });
$('#bumper1, .bumper1_desc').mouseleave(function () { $('.bumper1_desc').hide(); });
/////////////////////////////////////////////////////////////////////////////////
$('#wing1, .wing1_btn, .wing1_desc').hover(function (e) {
  e.preventDefault();
  var data = $('#wing1').data('maphilight') || {};
  data.alwaysOn = !data.alwaysOn;
  data.fillColor = 'ffca00';
  data.stroke = true;
  data.strokeColor = 'ffca00';
  data.strokeWidth = 2;
  $('#wing1').data('maphilight', data).trigger('alwaysOn.maphilight');
});
$('#wing1, .wing1_btn, .wing1_desc').mouseenter(function () { $('.wing1_desc').show(); });
$('#wing1, .wing1_btn, .wing1_desc').mouseleave(function () { $('.wing1_desc').hide(); });
/////////////////////////////////////////////////////////////////////////////////
$('#door, .door_btn, .door_desc').hover(function (e) {
  e.preventDefault();
  var data = $('#door').data('maphilight') || {};
  data.alwaysOn = !data.alwaysOn;
  data.fillColor = 'ffca00';
  data.stroke = true;
  data.strokeColor = 'ffca00';
  data.strokeWidth = 2;
  $('#door').data('maphilight', data).trigger('alwaysOn.maphilight');
});
$('#door, .door_btn, .door_desc').mouseenter(function () { $('.door_desc').show(); });
$('#door, .door_btn, .door_desc').mouseleave(function () { $('.door_desc').hide(); });
/////////////////////////////////////////////////////////////////////////////////
$('#roof, .roof_btn, .roof_desc').hover(function (e) {
  e.preventDefault();
  var data = $('#roof').data('maphilight') || {};
  data.alwaysOn = !data.alwaysOn;
  data.fillColor = 'ffca00';
  data.stroke = true;
  data.strokeColor = 'ffca00';
  data.strokeWidth = 2;
  $('#roof').data('maphilight', data).trigger('alwaysOn.maphilight');
});
$('#roof, .roof_btn, .roof_desc').mouseenter(function () { $('.roof_desc').show(); });
$('#roof, .roof_btn, .roof_desc').mouseleave(function () { $('.roof_desc').hide(); });
/////////////////////////////////////////////////////////////////////////////////
$('#bumper2, .bumper2_btn, .bumper2_desc').hover(function (e) {
  e.preventDefault();
  var data = $('#bumper2').data('maphilight') || {};
  data.alwaysOn = !data.alwaysOn;
  data.fillColor = 'ffca00';
  data.stroke = true;
  data.strokeColor = 'ffca00';
  data.strokeWidth = 2;
  $('#bumper2').data('maphilight', data).trigger('alwaysOn.maphilight');
});
$('#bumper2, .bumper2_btn, .bumper2_desc').mouseenter(function () { $('.bumper2_desc').show(); });
$('#bumper2, .bumper2_btn, .bumper2_desc').mouseleave(function () { $('.bumper2_desc').hide(); });
/////////////////////////////////////////////////////////////////////////////////
$('#wing2, .wing2_btn, .wing2_desc').hover(function (e) {
  e.preventDefault();
  var data = $('#wing2').data('maphilight') || {};
  data.alwaysOn = !data.alwaysOn;
  data.fillColor = 'ffca00';
  data.stroke = true;
  data.strokeColor = 'ffca00';
  data.strokeWidth = 2;
  $('#wing2').data('maphilight', data).trigger('alwaysOn.maphilight');
});
$('#wing2, .wing2_btn, .wing2_desc').mouseenter(function () { $('.wing2_desc').show(); });
$('#wing2, .wing2_btn, .wing2_desc').mouseleave(function () { $('.wing2_desc').hide(); });
/////////////////////////////////////////////////////////////////////////////////
// $('#trunk,  .trunk_desc').hover(function (e) {
//   e.preventDefault();
//   var data = $('#trunk').data('maphilight') || {};
//   data.alwaysOn = !data.alwaysOn;
//   data.fillColor = 'ffca00';
//   data.stroke = true;
//   data.strokeColor = 'ffca00';
//   data.strokeWidth = 2;
//   $('#trunk').data('maphilight', data).trigger('alwaysOn.maphilight');
// });
// $('#trunk, .trunk_desc').mouseenter(function () { $('.trunk_desc').show(); });
// $('#trunk, .trunk_desc').mouseleave(function () { $('.trunk_desc').hide(); });
// /////////////////////////////////////////////////////////////////////////////////
// $('#arches, .arches_desc').hover(function (e) {
//   e.preventDefault();
//   var data = $('#arches').data('maphilight') || {};
//   data.alwaysOn = !data.alwaysOn;
//   data.fillColor = 'ffca00';
//   data.stroke = true;
//   data.strokeColor = 'ffca00';
//   data.strokeWidth = 2;
//   $('#arches').data('maphilight', data).trigger('alwaysOn.maphilight');
// });
// $('#arches, .arches_desc').mouseenter(function () { $('.arches_desc').show(); });
// $('#arches, .arches_desc').mouseleave(function () { $('.arches_desc').hide(); });

function first() {
  var first1 = $('.first_thumb').val();
  
  if (first1 == 'true') {
    $(function () {
      var data = $('#hood').data('maphilight') || {};
      data.alwaysOn = !data.alwaysOn;
      data.fillColor = 'ffca00';
      data.stroke = true;
      data.strokeColor = 'ffca00';
      data.strokeWidth = 2;
      $('#hood').data('maphilight', data).trigger('alwaysOn.maphilight');
    });
  }
}