

const setSlide = function(selector, slide) {
    $(selector).text(slide);
}

let time = new Date();
let targetTime = new Date(time.getFullYear(), time.getMonth(), time.getDate());
targetTime = targetTime.valueOf()+1000*60*60*24;



const tick = function(init) {
    if (init == undefined) {
        init = false;
    }
    const currentTime = new Date().valueOf();
    if (currentTime > targetTime) {
        targetTime = new Date(time.getFullYear(), time.getMonth(), time.getDate());
        targetTime = targetTime.valueOf()+1000*60*60*24;			
    }
    let timeDiff = Math.floor((targetTime - currentTime)/1000);	
    var second2 = timeDiff % 10; timeDiff = Math.floor(timeDiff/10);
    var second1 = timeDiff % 6; timeDiff = Math.floor(timeDiff/6);
    var minute2 = timeDiff % 10; timeDiff = Math.floor(timeDiff/10);
    var minute1 = timeDiff % 6; timeDiff = Math.floor(timeDiff/6);
    var hour2 = Math.floor(timeDiff/10);
    var hour1 = timeDiff % 10;
    setSlide('span.secondPlay', second2);
    if ((second2 == 9) || init) {setSlide('span.second6Play', second1);
    if ((second1 == 5) || init) {setSlide('span.minutePlay', minute2);
    if ((minute2 == 9) || init) {setSlide('span.minute6Play', minute1);
    if ((minute1 == 5) || init) {setSlide('span.hourPlay', hour1);
    if ((hour2 == 9) || init) {setSlide('span.hour2Play', hour2);}}}}}
    }
tick(true);
setInterval(tick, 1000);