

window.popup = (id, form) => {
	console.log('call popup');
	$('#'+id).find(".formname").attr("value", form);
}

window.popup_photo = (form) => {
	var id = $(form).attr('name');
	var name = $(form).siblings('.name').children('input').val();
	var phone = $(form).siblings('.phone').children('input').val();
	$('#'+id).find('.name').children('input').val(name);
	$('#'+id).find('.phone').children('input').val(phone);
}

require('./leanModal');
$('a[rel*=leanModal]').leanModal({ top : 125, closeButton: ".modal_close" });


