require('../globals/scrollAnimate/scrollAnimate');
if (
  null === navigator.userAgent.toLowerCase().match(/(iphone|ipod|ipad|android)/)
) {
  $('.why')
    .on('animateIn', function() {
      var inter = 0;
      $(this)
        .find('.scale')
        .each(function() {
          var block = $(this);
          setTimeout(function() {
            block.css('opacity', 1);
            block.css('transform', 'scale(1.0, 1.0)');
          }, inter * 200);
          inter++;
        });
    })
    .on('animateOut', function() {
      $(this)
        .find('.scale')
        .each(function() {
          $(this).css('opacity', 0.01);
          $(this).css('transform', 'scale(0.5, 0.5)');
        });
    });
} else {
  $('.why .scale').css('opacity', 1);
}
