
require('./modules/header/header');

require('./modules/main/main');

require('./modules/counts/counts');

require('./modules/reasons/reasons');

require('./modules/offer/offer');

require('./modules/why/why');

require('./modules/price/price');

require('./modules/hww/hww');

require('./modules/any_q/any_q');

require('./modules/map/map');

require('./modules/footer/footer');

require('./modules/modals/callback/callback');
require('./modules/modals/makeOrder/makeOrder');
require('./modules/modals/question/question');
require('./modules/reviews/reviews');
require('./modules/order/order');
require('./modules/orderPhoto/orderPhoto');
require('./modules/orderPhoto2/orderPhoto2.css');
require('./modules/modals/photo/photo.js');
require('./modules/modals/photo/photo.css');
require('./modules/projects/projects.css');
require('./modules/projects/projects.js');
require('./modules/modals/orderRecieved/orderRecieved.css');
require('./modules/modals/orderRecieved/orderRecieved.js');

const env = process.env.NODE_ENV;
if(env === 'production') {
    var script = document.createElement('script');
    script.onload = function () {
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
    
        gtag('config', 'UA-4273609-3');       
    };
    script.src = '//www.googletagmanager.com/gtag/js?id=UA-4273609-3';
    document.head.appendChild(script); 
}
