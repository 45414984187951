$('.counts em').each(function() {
  $(this).attr('data-number', parseInt($(this).text()));
});

$('.counts').on('animateIn', function() {
  $(this).find('em').each(function() {
    var count =  parseInt($(this).attr('data-number'));
    var block = $(this);
    var timeout = null;
    var step = 1;
    timeout = setInterval(function() {
      if (step == 40) {
        block.text(count.toString());
        clearInterval(timeout);
      } else {
        block.text((Math.floor(count*step/25)).toString());
        step++;
      }
    }, 60);
  });
});