 require('../globals/scrollAnimate/scrollAnimate');

  
	if(null === navigator.userAgent.toLowerCase().match(/(iphone|ipod|ipad|android)/)){ 
  $('.reasons').on('animateIn', function() {
    var inter = 0;
    $(this).find('.fade').each(function() {
      var block = $(this);
      setTimeout(function() {
        block.css('opacity', 1);
      }, inter*500);
      inter++;
    });
  }).on('animateOut', function() {
    $(this).find('.fade').each(function() {
      $(this).css('opacity', 0.01);
    });
  });
}
  
 

